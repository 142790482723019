import React from 'react';
import { FeedType } from '../models/feedType';
import FeedDetailEditPage from '../components/pages/FeedDetail/EditPage';
import { getLocalStorage } from '../utils/localStorage';
import { LocalStorageKey } from '../models/localStorage';
import { getQueryString } from '../utils/queryString';
import { QueryStringKey } from '../models/queryString';
import { changeEnableScroll } from '../utils/bridge';

export default function FeedDetailEdit() {
  React.useEffect(() => {
    changeEnableScroll(true);
  }, []);

  const accessToken = getLocalStorage(LocalStorageKey.ACCESS_TOKEN);
  const channelId = getQueryString(QueryStringKey.CHANNEL_ID);
  const feedId = getQueryString(QueryStringKey.FEED_ID);
  const feedType: FeedType = (() => {
    const originalFeedType = getQueryString(QueryStringKey.FEED_TYPE);

    if (originalFeedType === 'creator') return FeedType.CREATOR;
    if (originalFeedType === 'user') return FeedType.USER;

    return FeedType.USER; // 2022-10-24 기준 커뮤니티(유저피드)만 수정 가능
  })();

  if (!channelId || !feedId || !accessToken) {
    // 404로 보내기 혹은 웹뷰 종료
    return <></>;
  }

  return (
    <FeedDetailEditPage
      accessToken={accessToken}
      channelId={channelId}
      feedId={feedId}
      feedType={feedType}
    />
  );
}
